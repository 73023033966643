<template>
  <div>
    <van-nav-bar
      title="查询列表"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div id="app" class="container">
      <!-- 查询表单 -->
      <form @submit.prevent="fetchData" class="search-form">
        <div class="form-group">
          <div class="form-label">
            <label for="country">所在国家:</label>
          </div>
          <select v-model="query.country" class="form-control">
            <option value="" disabled>请选择国家</option>
            <option
              v-for="country in countries"
              :key="country"
              :value="country"
            >
              {{ country }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <div class="form-label">
            <label for="name">姓名:</label>
          </div>

          <input
            type="text"
            v-model="query.name"
            placeholder="请输入姓名"
            class="form-control"
          />
        </div>
      </form>
      <div class="btncenter">
        <button type="submit" class="btn btn-primary" @click="fetchData">
          查询
        </button>
      </div>

      <!-- 数据列表 -->
      <table class="data-table">
        <thead>
          <tr>
            <th>姓名</th>
            <th>国家</th>
            <th>完成时间</th>

            <th>测评结果</th>
            <th>报告</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(report, index) in reports" :key="report.id">
            <td :title="report.name">{{ report.name }}</td>
            <td :title="report.country">{{ report.country }}</td>
            <td :title="report.endTime">{{ report.endTime }}</td>

            <td
              style="color: red"
              :title="report.fengxian"
              v-if="report.fengxian == '高风险'"
            >
              高风险
            </td>
            <td
              style="color: yellow"
              :title="report.fengxian"
              v-if="report.fengxian == '中风险'"
            >
              中风险
            </td>
            <td
              style="color: blue"
              :title="report.fengxian"
              v-if="report.fengxian == '低风险'"
            >
              低风险
            </td>

            <td
              style="color: green"
              :title="report.fengxian"
              v-if="report.fengxian == '当前无情绪风险'"
            >
              无情绪风险
            </td>
            <td>
              <a class="view-link" @click="goreport(report.subId)">查看</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      query: {
        country: "",
        name: "",
      },
      countries: [
        "中国",
        "美国/加拿大",
        "乍得",
        "哈萨克斯坦",
        "苏丹",
        "尼日尔",
        "阿尔及利亚",
        "伊朗",
        "伊拉克",
        "沙特阿拉伯",
        "阿曼",
        "印度",
        "印度尼西亚",
        "土库曼斯坦",
        "委内瑞拉",
        "叙利亚",
        "俄罗斯",
        "阿塞拜疆",
        "尼日利亚",
        "未知",
        "乌兹别克斯坦",
        "尼罗河",
        "土库曼",
        "阿姆河",
        "毛塔",
        "赞比亚",
        "南非",
        "日本",
        "缅甸",
        "坦桑尼亚",
        "安哥拉",
        "喀麦隆",
        "古巴",
        "意大利",
        "澳大利亚",
        "越南",
        "孟加拉",
        "突尼斯",
        "迪拜",
        "西非",
        "塔吉克斯坦",
        "墨西哥",
        "法国",
        "阿联酋",
        "巴西",
        "乌克兰",
        "斯洛伐克",
        "菲律宾",
        "爱尔兰",
        "西班牙",
        "德国",
        "哥伦比亚",
        "土耳其",
        "南苏丹",
        "索马里",
        "秘鲁",
        "智利",
        "马来西亚",
        "约旦",
        "厄瓜多尔",
        "博茨瓦纳",
        "芬兰",
        "巴拿马",
        "埃及",
        "泰国",
        "瑞典",
        "摩洛哥",
        "柬埔寨",
        "荷兰",
        "英国",
        "瑞士",
        "危地马拉",
        "肯尼亚",
        "毛里求斯",
        "阿根廷",
        "立陶宛",
        "新加坡",
        "加纳",
        "尼泊尔",
        "葡萄牙",
        "巴林",
        "波兰",
        "乌拉圭",
        "巴基斯坦",
        "马里",
        "埃塞俄比亚",
        "保加利亚",
        "刚果金",
        "科威特",
        "罗马尼亚",
        "贝宁",
        "乌干达",
      ],
      reports: [],
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goreport(subId) {
      this.$router.push({
        path: "/CP/huawei/TCReport",
        query: { subId },
      });
    },
    async fetchData() {
      try {
        const params = new URLSearchParams({
          country: this.query.country,
          name: this.query.name,
        });
        const response = await fetch(
          `https://assess.eapchina.net/zclist4HW?${params}`

          // `http://192.168.1.217:8081/assess/zclist4HW?${params}`
        );
        const data = await response.json();
        this.reports = data.data;
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    },
    async fetchQuestions() {
      try {
        const response = await fetch(
          "https://assess.eapchina.net/zclist4HW"

          // "http://192.168.1.217:8081/assess/zclist4HW"
        );
        const data = await response.json();
        this.reports = data.data;
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    },
  },
  created() {
    this.fetchQuestions(); // 初始加载数据
  },
};
</script>

<style scoped>
/* 容器 */
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* 搜索表单 */
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  min-width: 200px;
}

.form-control {
  flex: 1;
  padding: 4px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.btncenter {
  display: flex;
  justify-content: center;
}
.btn {
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* 数据表格 */
/* 数据表格 */
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed; /* 固定表格布局 */
}

.data-table th,
.data-table td {
  padding: 10px 4px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  word-break: break-all; /* 自动换行 */
  overflow: hidden; /* 隐藏溢出内容 */
  text-overflow: ellipsis; /* 溢出时显示省略号 */
  white-space: nowrap; /* 不换行 */
  font-size: 14px;
  text-align: center;
}

/* 根据需要调整列宽 */
.data-table th:nth-child(1),
.data-table td:nth-child(1) {
  width: 10%;
} /* 工号 */
.data-table th:nth-child(2),
.data-table td:nth-child(2) {
  width: 10%;
} /* 姓名 */
.data-table th:nth-child(3),
.data-table td:nth-child(3) {
  width: 20%;
} /* 所在国家 */
.data-table th:nth-child(4),
.data-table td:nth-child(4) {
  width: 17%;
} /* 完成时间 */
.data-table th:nth-child(5),
.data-table td:nth-child(5) {
  width: 7%;
} /* 查看 */

.view-link {
  color: #007bff;
  text-decoration: none;
}

.view-link:hover {
  text-decoration: underline;
}
.form-label {
  width: 70px;
}
.form-group {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
